.interna__melhorias__entregues{
	padding: 100px 0px;

	.titulo__melhorias{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 16px;
	}

	.desc__melhorias{
		font-family: 'RobotoRegular';
		font-size: 16px;
		line-height: 1.3;
		color: #000;

		margin-bottom: 28px;
	}

	.segura__imagens{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;

		padding-bottom: 50px;

		a{
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-down(lg){
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

