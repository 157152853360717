.banner__carousel{
	max-width: 1950px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	z-index: 1;
	.carousel-inner{
		max-width: 1920px;
		width: 100%;
		justify-content: center;
	}
	.carousel-indicators{
		margin-bottom: 57px;

		@include media-breakpoint-down(md){
			top: auto;
			margin-bottom: 14px;

			left: 0;
			right: 0;
		}
		li{
			text-indent: 0;
			top: 0;
			border-bottom: 0;
			border-top: 0;
			width: 20px;
			height: 20px;
			opacity: 1;
			background-color: transparent;
			position: relative;
			margin: 0px 15px;

			&:after{
				content: '';
				position: absolute;
				background-color: #FFF;
				width: 40px;
				height: 4px;
				bottom: -4px;
				left: calc(50% - 20px);
				opacity: 0.5;
				z-index: 1;
			}

			span{
				display: flex;
				align-items: center;
				justify-content: center;

				font-family: 'RobotoMedium';
				font-size: 14px;
				color: rgba(255, 255, 255, 0.64);
				position: relative;
				z-index: 2;
			}

			&.active{

				span{
					color: #FFF!important;
				}

				&:after{
					opacity: 1;
				}
			}
		}
	}
}

.sobre__produto{
	padding: 50px 0px;

	.titulo__sobre__produto{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 30px;
	}

	.segura__cards__sobre{
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 30px;

		@include media-breakpoint-down(lg){
			grid-template-columns: repeat(1, 1fr);
		}

		.card__sobre{
			display: flex;
			align-items: center;
			flex-direction: column;
			width: 100%;
			max-width: 540px;

			position: relative;

			@include media-breakpoint-down(lg){
				margin-left: auto;
				margin-right: auto;
			}

			.card__sobre__header{

				&::before{
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					border-radius: 20px;
					background: rgb(0,0,0);
					background: linear-gradient(180deg, rgba(0,0,0,0) 10%, rgba(247,71,83,0.7371323529411764) 70%);
					left: 50%;
					top: 50%;
					z-index: 1;
					transform: translate(-50%, -50%);
				}

				@include media-breakpoint-down(md){
					img{
						max-width: 100%;
						height: auto;
					}
				}
			}

			.card__sobre__body{
				position: absolute;
				left: 50%;
				bottom: -48px;
				z-index: 1;
				transform: translate(-50%, -50%);

				.nome__produto{
					font-family: 'RobotoBold';
					font-size: 40px;
					line-height: 1.2;
					color: #FFF;
					text-align: center;

					width: 400px;

					margin-bottom: 50px;
				}

				.btn__saiba{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 200px;
					height: 50px;

					background-color: transparent;

					border: 2px solid #FFF;
					border-radius: 30px;

					font-family: 'RobotoBold';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;
					text-decoration: none;

					margin-right: auto;
					margin-left: auto;

					&:hover{
						background-color: #FFF;
						color: #f74753;

						transition: all 0.5s linear;
					}
				}
			}
		}
	}
}

.sobre__nos{
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-color: #f74854;
	padding-top: 180px;

	.col__infos{

		.titulo__sobre__nos{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #FFF;

			margin-bottom: 12px;
		}

		.desc__sobre__nos{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #FFF;

			margin-bottom: 30px;
		}

		.btn__saiba{
			display: flex;
			align-items: center;
			justify-content: center;

			width: 200px;
			height: 50px;

			background-color: #FFF;

			border-radius: 30px;

			font-family: 'RobotoBold';
			font-size: 16px;
			line-height: 1.2;
			color: #f74753;
			text-decoration: none;
		}
	}

	.segura__cards__chamada{
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		transform: translateY(125px);

		@include media-breakpoint-down(lg){
			grid-template-columns: repeat(1, 1fr);
		}

		.card__chamada{
			position: relative;

			@include media-breakpoint-down(lg){
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			card__chamada__header{

			}

			.card__chamada__body{
				position: absolute;
				top: calc(50% - 105px);
				left: calc(50% - 155px);

				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				width: 310px;
				height: 210px;

				border-radius: 20px;
				background-color: rgba(247, 71, 83, 0.58);


				.nome__chamada{
					font-family: 'RobotoBold';
					font-size: 18px;
					line-height: 1.2;
					color: #FFF;
					text-align: center;

					margin-bottom: 12px;
				}

				.desc__chamada{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;
					text-align: center;

					max-width: 234px;
					margin-bottom: 20px;

					--linhas: 3;
				}

				.icone__chamada{
					color: #FFF;
				}
			}
		}
	}
}

.noticias__home{
	padding-top: 170px;
	padding-bottom: 50px;

	.titulo__noticias__home{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 16px;
	}

	.owl__noticias{
		margin-bottom: 40px;

		.card__noticia{
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;

			@include media-breakpoint-down(md){
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			.card__noticia__header{
				margin-bottom: 16px;

				position: relative;

				img{
					border-radius: 20px;
				}

				&::before{
					content: '';
					position: absolute;

					width: 310px;
					height: 210px;

					border-radius: 20px;
					background-color: rgba(247, 71, 83, 0.58);

					left: 50%;
					top: 50%;
					z-index: 1;
					transform: translate(-50%, -50%);
					opacity: 0;
				}

				&:after{
					background-image: url(../images/icone-noticia.png);
					background-repeat: no-repeat;
					background-size: auto;
					background-position: center;
					width: 30px;
					height: 30px;
					z-index: 2;

					content: '';
					position: absolute;
					left: calc(50% - 15px);
					top: calc(50% - 15px);
					opacity: 0;
				}
			}

			.card__noticia__body{

				.nome__noticia{
					font-family: 'RobotoBold';
					font-size: 18px;
					line-height: 1.2;
					color: #000;

					margin-bottom: 8px;
				}

				.desc__noticia{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.3;
					color: #000;

					--linhas: 3;
				}
			}

			&:hover{

				.card__noticia__header{

					&:before{
						opacity: 1;
						transition: all 0.5s linear;
					}

					&:after{
						opacity: 1;
						transition: all 0.5s linear;
					}
				}
			}
		}

		.owl-nav {
			position: relative;

			&.disabled{
				display: none;
			}

			button.owl-next {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 30px;
				height: 30px;

				background-color: transparent;

				border: 2px solid #000;
				border-radius: 50%;

				font-size: 18px;
				color: #000;

				position: absolute;
				top: calc(50% - 290px);
				right: -30px;

				@include media-breakpoint-down(md){
					right: -5px;
				}

				&:hover {
					border-color: #f74753;
					color: #f74753;
					transition: all 0.4s linear;
				}
			}

			button.owl-prev {
				display: flex;
				align-items: center;
				justify-content: center;

				width: 30px;
				height: 30px;

				background-color: transparent;

				border: 2px solid #000;
				border-radius: 50%;

				font-size: 18px;
				color: #000;

				position: absolute;
				top: calc(50% - 290px);
				left: -34px;

				@include media-breakpoint-down(md){
					left: -5px;
				}

				&:hover {
					border-color: #f74753;
					color: #f74753;
					transition: all 0.4s linear;
				}
			}
		}
	}

	.btn__noticias{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

.contato__home{
	padding-bottom: 100px;

	.container__contato{
		border-top: 5px solid #e4e4e4;
		padding-top: 60px;

		.col__formulario{

			.titulo__contato{
				font-family: 'RobotoBold';
				font-size: 40px;
				line-height: 1.2;
				color: #f74753;

				margin-bottom: 16px;
			}

			.contato__input{
				display: block;
				width: 100%;
				height: 54px;
				background-color: transparent;
				border: 1px solid #dbdbdb;
				color: #000;
				padding: 15px 25px;
				font-family: 'RobotoRegular';
				resize: none;

				border-radius: 54px;

				&:focus{
					outline: none;
				}

				&::placeholder{
					color: #000;
				}
			}

			.contato__input2{
				display: block;
				width: 100%;
				height: 54px;
				background-color: transparent;
				border: none;
				color: #000;
				padding: 15px 25px;
				font-family: 'RobotoRegular';
				resize: none;

				border-radius: 54px;

				&:focus{
					outline: none;
				}

				&::placeholder{
					color: #000;
				}
			}

			.form__contato{
				display: grid;
				max-width: 970px;
				margin-right: auto;
				margin-left: auto;

				@include media-breakpoint-down(md) {
					display: block;
				}
				grid-template-columns: 1fr 1fr;
				column-gap: 10px;

				.form-group {
					margin-bottom: 10px;
				}

				.colspan {
					grid-column: span 2;
				}

				.colspan2 {
					grid-column: span 1;
				}

				.segura__botao{
					display: flex;
					flex-direction: column;
					align-items: flex-end;

					background-color: transparent;
					border: 1px solid #dbdbdb;

					font-family: 'RobotoRegular';
					color: #000;
					resize: none;

					border-radius: 30px;

					padding-right: 20px;
					padding-bottom: 15px;
				}


				@include media-breakpoint-down(md){
					display: block;
				}

				.form-group{
					margin-bottom: 10px;
				}

				.v-select-output{
					display: block;

					width: 100%;
					height: 54px;

					background-color: transparent;
					border: 1px solid #dbdbdb;

					font-family: 'RobotoRegular';
					color: #000;
					resize: none;

					padding: 15px 25px;

					border-radius: 54px;
					resize: none;

					&:focus{
						outline: none;
						box-shadow: none;
					}
					&::placeholder{
						color: #3a3b3c;
					}
				}

				.enviar__botao{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;

					border: none;
					border-radius: 50%;

					background-color: #f74753;
					color: #fff;

					font-size: 20px;
				}

				.custom__check{
					position: fixed;
					left: -200vw;

					&:checked{
						& ~ .custom__label{
							&:after{
								opacity: 1;
							}
						}
					}
				}

				.custom__label{
					position: relative;
					padding-left: 25px;
					cursor: pointer;
					color: #000;
					font-family: 'RobotoRegular';

					a{
						font-family: 'RobotoRegular';
					}

					&::before{
						content: '';
						position: absolute;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						border: 2px solid #000;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
					&::after{
						content: '';
						position: absolute;
						width: 9px;
						height: 9px;
						border-radius: 50%;
						background-color: #000;
						left: 3px;
						top: 50%;
						transform: translateY(-50%);
						opacity: 0;
						transition: all 0.3s linear;
					}
				}
			}
		}

		.col__mapa{

			.box__mapa{
				max-width: 540px;
				height: 460px;

				border-radius: 30px;
			}
		}
	}
}

.politica__de__privacidade{

	.container__politica__de__privacidade {
		padding-top: 100px;
		padding-bottom: 100px;
		.politica__titulo {
			font-family: 'RobotoBold';
			font-size: 36px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			margin-bottom: 30px;
		}
		.content__editable {
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 200px;
			height: 50px;

			background-color: transparent;

			border: 2px solid #f74753;
			border-radius: 30px;

			font-family: 'RobotoBold';
			font-size: 16px;
			line-height: 1.2;
			color: #f74753;
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;
		}
	}
}

.termos__de__uso{

	.container__termos__de__uso {
		padding-top: 100px;
		padding-bottom: 100px;
		.termos__titulo {
			font-family: 'RobotoBold';
			font-size: 36px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			margin-bottom: 30px;
		}
		.content__editable {
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
		}

		.text-center {
			padding-bottom: 20px;
		}

		.btn-voltar {
			display: flex;
			align-items: center;
			justify-content: center;

			width: 200px;
			height: 50px;

			background-color: transparent;

			border: 2px solid #f74753;
			border-radius: 30px;

			font-family: 'RobotoBold';
			font-size: 16px;
			line-height: 1.2;
			color: #f74753;
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;
		}
	}
}
