.interna__noticia{
	padding: 100px 0px;

	.col__noticia{

		.nome__noticia{
			font-family: 'RobotoBold';
			font-size: 30px;
			line-height: 1.2;
			color: #f74753;

			margin-bottom: 30px;
		}

		.segura__imagem{
			margin-bottom: 30px;
		}

		.desc__noticia{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.3;
			color: #000;
		}

		.segura__video__custom{
			max-width: 1111px;
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 40px;
		}

		.btn__voltar{
			display: flex;
			align-items: center;
			justify-content: center;

			width: 200px;
			height: 50px;

			background-color: transparent;

			border: 2px solid #f74753;
			border-radius: 30px;

			font-family: 'RobotoBold';
			font-size: 16px;
			line-height: 1.2;
			color: #f74753;
			text-decoration: none;

			margin-right: auto;
			margin-left: auto;

			@include media-breakpoint-down(md){
				margin-bottom: 20px;
			}
		}
	}

	.col__mais__lidas{

		.box__mais__lidas{
			background-color: #ededed;

			border-radius: 20px;

			.mais__lidas__header{
				background-color: #f74753;

				border-radius: 20px 20px 0px 0px;

				padding: 20px 0px;

				.titulo__mais__lidas{
					font-family: 'RobotoBold';
					font-size: 35px;
					line-height: 1.2;
					color: #FFF;
					text-align: center;
				}
			}

			.mais__lidas__body{
				padding: 20px;

				.card__noticia__lida{
					display: flex;
					flex-direction: column;

					position: relative;

					margin-bottom: 12px;

					&::before{
						content: '';

						width: 100%;
						height: 1px;

						background-color: #b3b3b3;

						position: absolute;
						bottom: 0;
						left: calc(50% - 50%);
					}

					.nome__noticia{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #000;

						margin-bottom: 8px;
					}

					.desc__noticia{
						font-family: 'RobotoRegular';
						font-size: 16px;
						line-height: 1.3;
						color: #000;
						margin-bottom: 20px;

						--linhas: 2;
					}
				}
			}
		}

		.box__mapa{
			max-width: 540px;
			height: 460px;

			border-radius: 30px;
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

