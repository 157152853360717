.interna__noticias{
	padding: 100px 0px;

	.titulo__noticias{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		max-width: 524px;

		margin-bottom: 48px;
		margin-right: auto;
		margin-left: auto;
	}

	.col__noticias{

		.segura__cards__noticias{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 30px;

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(1, 1fr);
			}

			.card__noticia{
				text-decoration: none;

				@include media-breakpoint-down(md){
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.card__noticia__header{
					margin-bottom: 16px;

					position: relative;

					img{
						border-radius: 20px;
					}

					&::before{
						content: '';
						position: absolute;

						width: 310px;
						height: 210px;

						border-radius: 20px;
						background-color: rgba(247, 71, 83, 0.58);

						left: 50%;
						top: 50%;
						z-index: 1;
						transform: translate(-50%, -50%);
						opacity: 0;
					}

					&:after{
						background-image: url(../images/icone-noticia.png);
						background-repeat: no-repeat;
						background-size: auto;
						background-position: center;
						width: 30px;
						height: 30px;
						z-index: 2;

						content: '';
						position: absolute;
						left: calc(50% - 15px);
						top: calc(50% - 15px);
						opacity: 0;
					}
				}

				.card__noticia__body{

					.nome__noticia{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #000;

						margin-bottom: 8px;
					}

					.desc__noticia{
						font-family: 'RobotoRegular';
						font-size: 16px;
						line-height: 1.3;
						color: #000;

						--linhas: 3;
					}
				}

				&:hover{

					.card__noticia__header{

						&:before{
							opacity: 1;
							transition: all 0.5s linear;
						}

						&:after{
							opacity: 1;
							transition: all 0.5s linear;
						}
					}
				}
			}
		}

		.pagination{
			display: flex;
			align-items: center;
			justify-content: center;
			padding-top: 20px;

			.control{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 36px;
				height: 36px;
				background-color: transparent;
				border-radius: 50%;

				border: 1px solid #000;

				font-size: 22px;
				line-height: 20px;
				color: #000;
				text-decoration: none;

				&:hover {
					background-color: #f74753;
					color: #FFF;
					border: none;
					transition: all 0.4s linear;
				}
			}

			.page-item{
				padding: 5px;

				.page-link{
					font-family: 'RobotoBold';
					font-size: 15px!important;
					color: #000;

					display: flex;
					align-items: center;
					justify-content: center;

					width: 36px;
					height: 36px;
					background-color: transparent;
					border-radius: 50%;

					border: 1px solid #000;

					font-size: 22px;
					line-height: 20px;
					color: #000;
					text-decoration: none;

					&:hover{
						background-color: #f74753;
						color: #FFF;
						border: none;
						transition: all 0.4s linear;
					}
				}
			}
			.page-item.active .page-link{
				background-color: #f74753;
				color: #FFF;
				border: none;
			}
		}
	}

	.col__mais__lidas{

		.box__mais__lidas{
			background-color: #ededed;

			border-radius: 20px;

			.mais__lidas__header{
				background-color: #f74753;

				border-radius: 20px 20px 0px 0px;

				padding: 20px 0px;

				.titulo__mais__lidas{
					font-family: 'RobotoBold';
					font-size: 35px;
					line-height: 1.2;
					color: #FFF;
					text-align: center;
				}
			}

			.mais__lidas__body{
				padding: 20px;

				.card__noticia__lida{
					display: flex;
					flex-direction: column;

					position: relative;

					margin-bottom: 12px;

					&::before{
						content: '';

						width: 100%;
						height: 1px;

						background-color: #b3b3b3;

						position: absolute;
						bottom: 0;
						left: calc(50% - 50%);
					}

					.nome__noticia{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #000;

						margin-bottom: 8px;
					}

					.desc__noticia{
						font-family: 'RobotoRegular';
						font-size: 16px;
						line-height: 1.3;
						color: #000;
						margin-bottom: 20px;

						--linhas: 2;
					}
				}
			}
		}

		.box__mapa{
			max-width: 540px;
			height: 460px;

			border-radius: 30px;
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

