.interna__onde__encontrar{
	padding: 100px 0px;

	.titulo__onde__encontrar{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 18px;
	}

	.desc__onde__encontrar{
		font-family: 'RobotoRegular';
		font-size: 16px;
		line-height: 1.2;
		color: #000;
		text-align: center;

		margin-bottom: 50px;
	}

	.mapa-brasil{
		filter: drop-shadow(0 0 4px rgba(#fff,0.4));

		margin-bottom: 30px;

		.revendedor{
			fill: #fa8991;
		}

		title{
			display: block;
		}

		g {
			fill: #dbdbdb;
			transition: all 0.3s linear;
			cursor: pointer;

			.revendedor{
				fill: #fa8991;
			}

			&:hover{
				fill: #f74753;
			}
			&.active{
				fill: #f74753;
			}


		}

		g#AM:hover{
			fill: #dbdbdb;
		}

		g#AC:hover{
			fill: #dbdbdb;
		}

		g#AP:hover{
			fill: #dbdbdb;
		}

		g#RO:hover{
			fill: #dbdbdb;
		}
	}

	select#estados{
		background-color: #e4e4e4;
		color: #666666;
		font-size: 13px;
		line-height: 17px;
		border: none;
		width: 100%;
		display: block;
		height: auto;
		padding: 14px 40px 12px 20px;
		position: relative;
		appearance: none;


		&:focus{
			outline: none;
		}

	}

	.segura__select{
		position: relative;
		margin-bottom: 50px;

		&:after{
			content: '';
			border-top: 6px solid #8e8e8e;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			position: absolute;
			z-index: 300;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@include media-breakpoint-down (md) {
		.mapa-brasil{
			pointer-events: none;
		}
		.segura__select{
			margin-bottom: 20px;
		}
	}

	@include media-breakpoint-up (lg) {

		.select__estado{
			display: none;
		}
	}

	.col__enderecos{
		padding-top: 100px;

		.encontrar__locals{

			@include media-breakpoint-down (md) {
				padding-top: 20px;
			}

			.encontrar__item{
				display: flex;
				margin-bottom: 34px;

				.encontrar__item__icone{
					width: 40px;
					height: 40px;
					min-width: 40px;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-right: 15px;
					background-color: #f74753;
					font-size: 20px;
					line-height: 20px;
					color: #fff;
				}

				.encontrar__item__conteudo{
					padding-top: 10px;
				}

				.encotrar__item__titulo{
					font-family: 'NunitoExtraBold';
					color: #777777;
					font-size: 16px;
					line-height: 1.2;
				}

				.encontrar__item__endereco{
					font-family: 'NunitoSemiBold';
					color: #777777;
					font-size: 16px;
					line-height: 1.2;
				}
			}
		}

		.encontrar__titulo{
			font-family: 'NunitoBlack';
			font-size: 20px;
			line-height: 1.2;
			color: #38b6ff;

			margin-bottom: 30px;
		}
	}

	.box__grande__capital{

		.titulo__grande__capital{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;

			margin-bottom: 100px;
		}

		.segura__cards{
			display: grid;
			grid-template-columns: repeat(4, 1fr);

			column-gap: 30px;
			row-gap: 70px;

			@include media-breakpoint-down(lg){
				grid-template-columns: repeat(3, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(sm){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(xs){
				grid-template-columns: repeat(1, 1fr);

				row-gap: 100px;
			}

			padding-bottom: 50px;
			.card__grande__capital{
				display: flex;
				flex-direction: column;
				align-items: center;

				border-radius: 20px;

				box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);

				margin-right: auto;
				margin-left: auto;

				.card__header{
					margin-top: -48px;

					img{
						border-radius: 50%;
						border: 6px solid #FFF;
					}
				}

				.card__body{
					padding: 30px 20px;

					.nome__grande__capital{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #f74753;
						text-align: center;

						margin-bottom: 18px;
					}

					.endereco__grande__capital{
						font-family: 'RobotoRegular';
						font-size: 14px;
						line-height: 1.2;
						color: #000;
						text-align: center;

						margin-bottom: 40px;

						--linhas: 5;
					}

					.btn__mapa{
						display: flex;
						align-items: center;
						justify-content: center;

						width: 200px;
						height: 40px;

						background-color: transparent;

						border: 2px solid #f74753;
						border-radius: 30px;

						font-family: 'RobotoBold';
						font-size: 16px;
						line-height: 1.2;
						color: #f74753;
						text-decoration: none;

						margin-right: auto;
						margin-left: auto;
					}

					.segura__icone{
						display: flex;
						flex-wrap: wrap;

						margin-top: 30px;

						img{
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
		}
	}

	.box__capital{

		.titulo__capital{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;

			margin-bottom: 100px;
		}

		.segura__cards{
			display: grid;
			grid-template-columns: repeat(4, 1fr);

			column-gap: 30px;
			row-gap: 70px;

			@include media-breakpoint-down(lg){
				grid-template-columns: repeat(3, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(sm){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(xs){
				grid-template-columns: repeat(1, 1fr);

				row-gap: 100px;
			}

			padding-bottom: 50px;
			.card__capital{
				display: flex;
				flex-direction: column;
				align-items: center;

				border-radius: 20px;

				box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);

				margin-right: auto;
				margin-left: auto;

				.card__header{
					margin-top: -48px;

					img{
						border-radius: 50%;
						border: 6px solid #FFF;
					}
				}

				.card__body{
					padding: 30px 20px;

					.nome__capital{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #f74753;
						text-align: center;

						margin-bottom: 18px;
					}

					.endereco__capital{
						font-family: 'RobotoRegular';
						font-size: 14px;
						line-height: 1.2;
						color: #000;
						text-align: center;

						margin-bottom: 40px;

						--linhas: 5;
					}

					.btn__mapa{
						display: flex;
						align-items: center;
						justify-content: center;

						width: 200px;
						height: 40px;

						background-color: transparent;

						border: 2px solid #f74753;
						border-radius: 30px;

						font-family: 'RobotoBold';
						font-size: 16px;
						line-height: 1.2;
						color: #f74753;
						text-decoration: none;

						margin-right: auto;
						margin-left: auto;
					}

					.segura__icone{
						display: flex;
						flex-wrap: wrap;

						margin-top: 30px;

						img{
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
		}
	}

	.box__interior{

		.titulo__interior{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;

			margin-bottom: 100px;
		}

		.segura__cards{
			display: grid;
			grid-template-columns: repeat(4, 1fr);

			column-gap: 30px;
			row-gap: 70px;

			@include media-breakpoint-down(lg){
				grid-template-columns: repeat(3, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(sm){
				grid-template-columns: repeat(2, 1fr);

				row-gap: 100px;
			}

			@include media-breakpoint-down(xs){
				grid-template-columns: repeat(1, 1fr);

				row-gap: 100px;
			}

			padding-bottom: 50px;
			.card__interior{
				display: flex;
				flex-direction: column;
				align-items: center;

				border-radius: 20px;

				box-shadow: 0px 0px 10px 6px rgba(0,0,0,0.1);

				margin-right: auto;
				margin-left: auto;

				.card__header{
					margin-top: -48px;

					img{
						border-radius: 50%;
						border: 6px solid #FFF;
					}
				}

				.card__body{
					padding: 30px 20px;

					.nome__interior{
						font-family: 'RobotoBold';
						font-size: 18px;
						line-height: 1.2;
						color: #f74753;
						text-align: center;

						margin-bottom: 18px;
					}

					.endereco__interior{
						font-family: 'RobotoRegular';
						font-size: 14px;
						line-height: 1.2;
						color: #000;
						text-align: center;

						margin-bottom: 40px;

						--linhas: 5;
					}

					.btn__mapa{
						display: flex;
						align-items: center;
						justify-content: center;

						width: 200px;
						height: 40px;

						background-color: transparent;

						border: 2px solid #f74753;
						border-radius: 30px;

						font-family: 'RobotoBold';
						font-size: 16px;
						line-height: 1.2;
						color: #f74753;
						text-decoration: none;

						margin-right: auto;
						margin-left: auto;
					}

					.segura__icone{
						display: flex;
						flex-wrap: wrap;

						margin-top: 30px;

						img{
							margin-left: auto;
							margin-right: auto;
						}
					}
				}
			}
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

