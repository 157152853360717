footer{
	background-color: #f74753;

	.redes__footer__creditos{
		display: none;
	}
}

.footer__interna{

	.rodape__conteudo{
		padding: 20px 0px;
	}

	.col__logo{

		.segura__logo__footer{
			margin-bottom: unset!important;
		}

		.redes__footer{
			display: none!important;
		}
	}

	.col__infos{
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-down(md){
			flex-direction: column;
		}

		.segura__telefones{
			margin: 0px 10px!important;

			@include media-breakpoint-down(md){
				margin: 10px 0px!important;
			}
		}

		.segura__email{
			margin: 0px 10px!important;

			@include media-breakpoint-down(md){
				margin: 10px 0px!important;
			}
		}

		.segura__endereco{
			margin: 0px 10px!important;

			@include media-breakpoint-down(md){
				margin: 10px 0px!important;
			}
		}
	}

	.col__informativo{
		display: none;
	}

	.rodape__creditos{

		.container__creditos{
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include media-breakpoint-down(md){
				flex-direction: column;
			}

			.redes__footer__creditos{
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@include media-breakpoint-down(md){
					padding-bottom: 30px;
					justify-content: center;
				}

				a{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border: 2px solid #FFF;
					border-radius: 50%;

					background-color: transparent;

					color: #FFF;
					font-size: 19px;
					text-decoration: none;

					margin: 0px 2px;

					&:hover{
						background-color: #FFF;
						color: #f74753;

						transition: all 0.5s linear;
					}
				}
			}
		}
	}
}

.rodape__conteudo{
	padding: 50px 0px;

	.container__footer{
		display: flex;
		align-items: center;
		justify-content: space-between;

		@include media-breakpoint-down(md){
			flex-direction: column;
		}

		.col__logo{
			@include media-breakpoint-down(md){
				margin: 20px 0px;
			}

			.segura__logo__footer{
				display: flex;
				align-items: center;

				margin-bottom: 40px;
			}

			.redes__footer{
				display: flex;
				align-items: center;
				justify-content: flex-start;

				@include media-breakpoint-down(md){
					padding-bottom: 30px;
					justify-content: center;
				}

				a{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;
					border: 2px solid #FFF;
					border-radius: 50%;

					background-color: transparent;

					color: #FFF;
					font-size: 19px;
					text-decoration: none;

					margin: 0px 2px;

					&:hover{
						background-color: #FFF;
						color: #f74753;

						transition: all 0.5s linear;
					}
				}
			}
		}

		.col__infos{
			@include media-breakpoint-down(md){
				margin: 20px 0px;
			}

			.segura__telefones{
				display: flex;
				align-items: center;
				justify-content: flex-start;

				margin: 7px 0px;

				@include media-breakpoint-down(md){
					flex-direction: column;
					margin: 10px 0px;
				}

				.icone__telefone{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;

					background-color: transparent;

					border: 2px solid #FFF;
					border-radius: 50%;

					color: #FFF;
				}

				.segura__links{
					display: flex;
					flex-direction: column;

					padding-left: 10px;

					@include media-breakpoint-down(md){
						padding-top: 8px;
						padding-left: unset;

						display: flex;
						align-items: center;
					}

					.link__tel{
						font-family: 'RobotoRegular';
						font-size: 14px;
						line-height: 1.2;
						color: #FFF;
					}
				}
			}

			.segura__email{
				display: flex;
				align-items: center;
				justify-content: flex-start;

				margin: 7px 0px;

				@include media-breakpoint-down(md){
					flex-direction: column;
					margin: 10px 0px;
				}

				.icone__email{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;

					background-color: transparent;

					border: 2px solid #FFF;
					border-radius: 50%;

					color: #FFF;
				}

				.segura__links{
					display: flex;
					flex-direction: column;

					padding-left: 10px;

					@include media-breakpoint-down(md){
						padding-top: 8px;
						padding-left: unset;
					}

					.link__email{
						font-family: 'RobotoRegular';
						font-size: 14px;
						line-height: 1.2;
						color: #FFF;
					}
				}
			}

			.segura__endereco{
				display: flex;
				align-items: center;
				justify-content: flex-start;

				margin: 7px 0px;

				@include media-breakpoint-down(md){
					flex-direction: column;
					margin: 10px 0px;
				}

				.icone__endereco{
					display: flex;
					align-items: center;
					justify-content: center;

					width: 40px;
					height: 40px;

					background-color: transparent;

					border: 2px solid #FFF;
					border-radius: 50%;

					color: #FFF;
				}

				.desc__endereco{
					font-family: 'RobotoRegular';
					font-size: 14px;
					line-height: 1.2;
					color: #FFF;

					max-width: 302px;

					padding-left: 10px;

					p{
						margin: unset;
					}

					@include media-breakpoint-down(md){
						padding-top: 8px;
						padding-left: unset;
					}
				}
			}
		}

		.col__informativo{
			background-color: #c63942;
			border-radius: 30px;

			padding: 24px;

			@include media-breakpoint-down(md){
				margin: 20px 0px;
			}

			.segura__horario{

				.titulo__horario{
					font-family: 'RobotoBold';
					font-size: 14px;
					line-height: 1.2;
					color: #FFF;
				}

				.desc__horario{
					font-family: 'RobotoRegular';
					font-size: 14px;
					line-height: 1.2;
					color: #FFF;

					max-width: 216px;
				}
			}

			.segura__cnpj{

				.desc__cnpj{
					font-family: 'RobotoRegular';
					font-size: 14px;
					line-height: 1.2;
					color: #FFF;

					max-width: 216px;
				}
			}
		}
	}
}

.rodape__creditos{
	font-family: 'PoppinsLight';
	color: #000000;

	border-top: 1px solid #FFF;

	.container__creditos {
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 16px 0px;

		@include media-breakpoint-down(md) {
			padding: 16px 10px;
		}

		.creditos {
			color: #FFF;
			font-size: 12px;
			font-family: 'NotoSansRegular';
			text-align: center;

			@include media-breakpoint-down(md) {
				padding-bottom: 20px;
			}

			span {
				font-family: 'NotoSansBold';
				text-transform: uppercase;
			}

			svg {
				margin-left: 5px;
				color: #FFF;
			}
		}
	}
}

