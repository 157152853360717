.interna__tratamento{
	padding: 100px 0px;

	.box__segura__links{
		padding-bottom: 90px;

		.box__links{
			display: grid;
			grid-template-columns: repeat(7, 1fr);

			gap: 20px;

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(2, 1fr);
			}

			.btn__link{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 150px;
				height: 40px;

				background-color: transparent;

				border: 2px solid #f74753;
				border-radius: 30px;

				font-family: 'RobotoBold';
				font-size: 16px;
				line-height: 1.2;
				color: #f74753;
				text-decoration: none;

				margin-right: auto;
				margin-left: auto;

				&:hover{
					background-color: #f74753;
					color: #FFF;

					transition: all 0.5s linear;
				}
			}
		}
	}

	.box__resfriamento{

		.titulo__resfiamento{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			margin-bottom: 20px;
		}

		.desc__resfiamento{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
			text-align: center;

			margin-bottom: 100px;

			img{
				max-width: 100%;
				height: auto!important;
			}
		}

		.segura__imagem__resfiamento{
			display: flex;
			align-items: center;
			justify-content: center;

			margin-right: auto;
			margin-left: auto;
		}
	}

	.box__historia{
		padding-top: 100px;

		.titulo__historia{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			max-width: 615px;

			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}

		.desc__noticia{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
			text-align: justify;

			margin-bottom: 50px;

			img{
				max-width: 100%;
				height: auto!important;
			}
		}

		.titulo__especificacoes{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			max-width: 615px;

			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
		}

		.sugura__especificacoes{
			padding-bottom: 80px;

			.especificacao{
				font-family: 'RobotoRegular';
				font-size: 16px;
				line-height: 1.2;
				color: #000;

				position: relative;

				padding-left: 30px;
				margin-bottom: 10px;

				&:after{
					content: '';

					position: absolute;
					left: 0;
					top: calc(50% - 5px);

					width: 10px;
					height: 10px;

					background-color: #f74753;

					border-radius: 50%;
				}
			}
		}

		.imagens__historia{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			margin-bottom: -120px;
			@include media-breakpoint-down(md){
				grid-template-columns: repeat(1, 1fr);
			}

			img{
				display: flex;
				align-items: center;
				justify-content: center;

				margin-left: auto;
				margin-right: auto;
			}
		}
	}

	.box__indicacoes{
		background-color: #f74753;

		padding-top: 220px;
		padding-bottom: 90px;

		.col__indicacoes{

			.titulo__indicacoes{
				font-family: 'RobotoBold';
				font-size: 40px;
				line-height: 1.2;
				color: #FFF;

				margin-bottom: 16px;
			}

			.desc__indicacoes{
				font-family: 'RobotoRegular';
				font-size: 16px;
				line-height: 1.2;
				color: #FFF;
				text-align: justify;

				margin-bottom: 50px;

				img{
					max-width: 100%;
					height: auto!important;
				}
			}
		}

		.col__contraindicacoes{

			.titulo__contraindicacoes{
				font-family: 'RobotoBold';
				font-size: 40px;
				line-height: 1.2;
				color: #FFF;

				margin-bottom: 16px;
			}

			.segura__contraindicacoes{

				.contraindicacao{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #FFF;

					position: relative;

					padding-left: 30px;
					margin-bottom: 10px;

					&:after{
						content: '';

						position: absolute;
						left: 0;
						top: calc(50% - 5px);

						width: 10px;
						height: 10px;

						background-color: #FFF;

						border-radius: 50%;
					}
				}
			}
		}

		.col__precaucoes{

			.titulo__precaucoes{
				font-family: 'RobotoBold';
				font-size: 40px;
				line-height: 1.2;
				color: #FFF;

				margin-bottom: 16px;
			}

			.desc__precaucoes{
				font-family: 'RobotoRegular';
				font-size: 16px;
				line-height: 1.2;
				color: #FFF;
				text-align: justify;

				margin-bottom: 50px;

				img{
					max-width: 100%;
					height: auto!important;
				}
			}
		}
	}

	.box__eventos{
		padding-top: 80px;
		padding-bottom: 50px;

		.titulo__evento{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;
		}

		.desc__eventos{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;

			text-align: justify;

			img{
				max-width: 100%;
				height: auto!important;
			}
		}
	}

	.box__tratamento{
		padding-top: 50px;

		.titulo__tratamento{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;
		}

		.desc__tratamento{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
			text-align: justify;

			margin-bottom: 100px;

			img{
				max-width: 100%;
				height: auto!important;
			}
		}
	}

	.box__pos__tratamento{
		padding-top: 100px;

		.titulo__pos__tratamento{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;
			text-align: center;

			margin-bottom: 30px;
		}

		.desc__pos__tratamento{
			font-family: 'RobotoRegular';
			font-size: 16px;
			line-height: 1.2;
			color: #000;
			text-align: justify;

			span{
				font-family: 'RobotoBold';
				margin-bottom: 10px;
			}

			img{
				max-width: 100%;
				height: auto!important;
			}
		}

		.imagens__pos__tratamento{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 20px;

			padding-top: 80px;

			@include media-breakpoint-down(md){
				grid-template-columns: repeat(1, 1fr);
			}
		}
	}

	.box__premio{
		padding-top: 100px;

		.container__premio{
			display: flex;
			align-items: center;
			justify-content: space-between;

			@include media-breakpoint-down(md){
				flex-direction: column;
			}
		}

		.segura__imagem__premio{

			@include media-breakpoint-down(md){
				margin-bottom: 20px;
			}
		}

		.infos__premios{

			.titulo__premios{
				font-family: 'RobotoBold';
				font-size: 40px;
				line-height: 1.2;
				color: #f74753;

				margin-bottom: 12px;
			}

			.desc__premios{
				font-family: 'RobotoRegular';
				font-size: 16px;
				line-height: 1.2;
				color: #000;
				text-align: justify;

				img{
					max-width: 100%;
					height: auto!important;
				}
			}
		}
	}
}

