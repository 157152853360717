@font-face {
    font-family: 'NotoSansBold';
    src: url('../fonts/NotoSansBold/NotoSans-Bold.eot');
    src: url('../fonts/NotoSansBold/NotoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NotoSansBold/NotoSans-Bold.woff2') format('woff2'),
        url('../fonts/NotoSansBold/NotoSans-Bold.woff') format('woff'),
        url('../fonts/NotoSansBold/NotoSans-Bold.ttf') format('truetype'),
        url('../fonts/NotoSansBold/NotoSans-Bold.svg#NotoSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'RobotoBold';
    src: url('../fonts/RobotoBold/Roboto-Bold.eot');
    src: url('../fonts/RobotoBold/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RobotoBold/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/RobotoBold/Roboto-Bold.woff') format('woff'),
        url('../fonts/RobotoBold/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/RobotoBold/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'NotoSansRegular';
    src: url('../fonts/NotoSansRegular/NotoSans-Regular.eot');
    src: url('../fonts/NotoSansRegular/NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.woff2') format('woff2'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.woff') format('woff'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.ttf') format('truetype'),
        url('../fonts/NotoSansRegular/NotoSans-Regular.svg#NotoSans-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'RobotoLight';
    src: url('../fonts/RobotoLight/Roboto-Light.eot');
    src: url('../fonts/RobotoLight/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RobotoLight/Roboto-Light.woff2') format('woff2'),
        url('../fonts/RobotoLight/Roboto-Light.woff') format('woff'),
        url('../fonts/RobotoLight/Roboto-Light.ttf') format('truetype'),
        url('../fonts/RobotoLight/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'RobotoMedium';
    src: url('../fonts/RobotoMedium/Roboto-Medium.eot');
    src: url('../fonts/RobotoMedium/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RobotoMedium/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/RobotoMedium/Roboto-Medium.woff') format('woff'),
        url('../fonts/RobotoMedium/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/RobotoMedium/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'RobotoRegular';
    src: url('../fonts/RobotoRegular/Roboto-Regular.eot');
    src: url('../fonts/RobotoRegular/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RobotoRegular/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/RobotoRegular/Roboto-Regular.woff') format('woff'),
        url('../fonts/RobotoRegular/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/RobotoRegular/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

