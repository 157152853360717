.interna__downloads{
	padding: 100px 0px;

	.titulo__downloads{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 18px;
	}

	.desc__downloads{
		font-family: 'RobotoRegular';
		font-size: 16px;
		line-height: 1.2;
		color: #000;
		text-align: center;

		margin-bottom: 50px;
	}

	.box__logos{
		display: grid;
		align-items: center;
		justify-content: center;
		grid-template-columns: repeat(2, 1fr);

		max-width: 526px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 100px;

		@include media-breakpoint-down(md){
			grid-template-columns: repeat(1, 1fr);
			row-gap: 40px;
		}

		.select__logo{
			margin: 0px 10px;

			img{
				margin-bottom: 20px;
			}

			.form__select{

				.v-select-output{
					display: block;

					width: 100%;
					height: 54px;

					background-color: transparent;
					border: 2px solid #f74753;

					font-family: 'RobotoBold';
					color: #f74753;
					resize: none;

					padding: 15px 25px;

					border-radius: 54px;
					resize: none;

					&:focus{
						outline: none;
						box-shadow: none;
					}

					&::placeholder{
						color: #3a3b3c;
					}

					&:hover{
						background-color: #f74753;
						color: #FFF;

						transition: all 0.5s linear;
					}

					.active{
						background-color: #f74753;
						color: #FFF;

						transition: all 0.5s linear;
					}
				}
			}
		}
	}

	.row__sumario{
		margin-bottom: 20px;

		@include media-breakpoint-down(md){
			display: none;
		}

		.titulo__sumario__df{
			font-family: 'RobotoBold';
			font-size: 30px;
			line-height: 1.2;
			color: #f74753;

			padding-left: 50px;
		}

		.titulo__sumario{
			font-family: 'RobotoBold';
			font-size: 30px;
			line-height: 1.2;
			color: #f74753;
		}
	}

	.segura__cards__downloads{
		border: 1px solid #c7c7c7;

		margin-bottom: 50px;

		.card__download{
			display: flex;
			align-items: center;
			justify-content: space-between;

			border-bottom: 1px solid #c7c7c7;

			padding-left: 50px;

			@include media-breakpoint-down(md){
				flex-direction: column-reverse;

				padding-left: unset;
			}

			.segura__infos__download{
				display: flex;
				align-items: center;
				justify-content: space-between;

				@include media-breakpoint-down(md){
					flex-direction: column;
					padding: 18px;
				}

				.desc__download{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #000;

					width: 610px;

					@include media-breakpoint-down(md){
						text-align: center;
						margin-bottom: 30px;
					}

					p{
						margin: 0;
					}
				}

				.nome__download{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #000;

					padding-left: 10px;

					@include media-breakpoint-down(md){
						text-align: center;
						padding-left: unset;
					}
				}
			}

			.btn__download{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 100px;
				height: 100px;

				border: 1px solid #f74753;

				padding: 20px;

				color: #f74753;

				@include media-breakpoint-down(md){
					width: 100%;
				}

				&:hover{
					background-color: #f74753;
					color: #FFF;

					transition: all 0.5s linear;
				}
			}
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

