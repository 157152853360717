.interna__contato{
	padding: 100px 0px;

	.col__formulario{

		.titulo__contato{
			font-family: 'RobotoBold';
			font-size: 40px;
			line-height: 1.2;
			color: #f74753;

			margin-bottom: 16px;
		}

		.contato__input{
			display: block;
			width: 100%;
			height: 54px;
			background-color: transparent;
			border: 1px solid #dbdbdb;
			color: #000;
			padding: 15px 25px;
			font-family: 'RobotoRegular';
			resize: none;

			border-radius: 54px;

			&:focus{
				outline: none;
			}

			&::placeholder{
				color: #000;
			}
		}

		.contato__input2{
			display: block;
			width: 100%;
			height: 54px;
			background-color: transparent;
			border: none;
			color: #000;
			padding: 15px 25px;
			font-family: 'RobotoRegular';
			resize: none;

			border-radius: 54px;

			&:focus{
				outline: none;
			}

			&::placeholder{
				color: #000;
			}
		}

		.form__contato{
			display: grid;
			max-width: 970px;
			margin-right: auto;
			margin-left: auto;

			@include media-breakpoint-down(md) {
				display: block;
			}
			grid-template-columns: 1fr 1fr;
			column-gap: 10px;

			.form-group {
				margin-bottom: 10px;
			}

			.colspan {
				grid-column: span 2;
			}

			.colspan2 {
				grid-column: span 1;
			}

			.segura__botao{
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				background-color: transparent;
				border: 1px solid #dbdbdb;

				font-family: 'RobotoRegular';
				color: #000;
				resize: none;

				border-radius: 30px;

				padding-right: 20px;
				padding-bottom: 15px;
			}


			@include media-breakpoint-down(md){
				display: block;
			}

			.form-group{
				margin-bottom: 10px;
			}

			.v-select-output{
				display: block;

				width: 100%;
				height: 54px;

				background-color: transparent;
				border: 1px solid #dbdbdb;

				font-family: 'RobotoRegular';
				color: #000;
				resize: none;

				padding: 15px 25px;

				border-radius: 54px;
				resize: none;

				&:focus{
					outline: none;
					box-shadow: none;
				}
				&::placeholder{
					color: #3a3b3c;
				}
			}

			.enviar__botao{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 40px;
				height: 40px;

				border: none;
				border-radius: 50%;

				background-color: #f74753;
				color: #fff;

				font-size: 20px;
			}

			.custom__check{
				position: fixed;
				left: -200vw;

				&:checked{
					& ~ .custom__label{
						&:after{
							opacity: 1;
						}
					}
				}
			}

			.custom__label{
				position: relative;
				padding-left: 25px;
				cursor: pointer;
				color: #000;
				font-family: 'RobotoRegular';

				a{
					font-family: 'RobotoRegular';
				}

				&::before{
					content: '';
					position: absolute;
					width: 15px;
					height: 15px;
					border-radius: 50%;
					border: 2px solid #000;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
				&::after{
					content: '';
					position: absolute;
					width: 9px;
					height: 9px;
					border-radius: 50%;
					background-color: #000;
					left: 3px;
					top: 50%;
					transform: translateY(-50%);
					opacity: 0;
					transition: all 0.3s linear;
				}
			}
		}
	}

	.col__mapa{

		.box__mapa{
			max-width: 540px;
			height: 460px;

			border-radius: 30px;
		}
	}

	.segura__logos{
		display: grid;
		align-items: center;
		justify-content: center;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;

		padding-top: 100px;

		img{
			margin-left: auto;
			margin-right: auto;
		}

		@include media-breakpoint-down(md){
			grid-template-columns: repeat(1, 1fr);
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

