.interna__perguntas__frequentes{
	padding: 100px 0px;

	.titulo__perguntas{
		font-family: 'RobotoBold';
		font-size: 40px;
		line-height: 1.2;
		color: #f74753;
		text-align: center;

		margin-bottom: 46px;
	}

	.segura__cards__perguntas{
		display: grid;

		gap: 30px;

		padding-bottom: 40px;

		.card__pergunta{
			display: flex;

			@include media-breakpoint-down(md){
				flex-direction: column;
				align-items: center;
			}

			.segura__imagem{
				display: flex;
				align-items: center;
				justify-content: center;

				width: 100px;
				height: 100px;

				border-radius: 24px;

				background-color: #f74753;

				padding: 20px;
			}

			.segura__conteudo{
				flex-direction: column;

				padding-left: 24px;

				@include media-breakpoint-down(md){
					padding-left: unset;
					padding-top: 16px;
				}

				.nome__pergunta{
					font-family: 'RobotoBold';
					font-size: 16px;
					line-height: 1.2;
					color: #000;
				}

				.desc__pergunta{
					font-family: 'RobotoRegular';
					font-size: 16px;
					line-height: 1.2;
					color: #000;
					text-align: justify;
				}
			}
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;
	}
}

