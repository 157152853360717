.interna__quem__somos{
	padding: 100px 0px;

	.container__personalizado{

		@include media-breakpoint-down(lg){
			display: flex;
			align-items: center;
			flex-direction: column;
		}
	}

	.row__personalizada{
		padding-top: 30px;
	}

	.desc__quem__somos{
		font-family: 'RobotoRegular';
		font-size: 16px;
		line-height: 1.2;
		color: #000;
		text-align: justify;

		img{
			margin-bottom: 30px;
		}

		@include media-breakpoint-down(lg){
			order: 1;
		}
	}

	.col__imagens{

		.segura__logos{
			display: grid;
			grid-template-columns: repeat(3, 1fr);

			column-gap: 30px;

			padding: 50px 0px;

			@include media-breakpoint-down(lg){
				order: 3;
			}

			@include media-breakpoint-down(md){
				display: flex;
				align-items: center;
				flex-direction: column;
			}
			img{
				margin: 0px 20px;

				@include media-breakpoint-down(md){
					margin: 20px 0px;
				}
			}
		}
	}

	.btn__voltar{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 200px;
		height: 50px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 30px;

		font-family: 'RobotoBold';
		font-size: 16px;
		line-height: 1.2;
		color: #f74753;
		text-decoration: none;

		margin-right: auto;
		margin-left: auto;

		@include media-breakpoint-down(lg){
			order: 4;
		}
	}
}

