.bg__menu{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1050;
	animation: fadeIn 0.5s linear;

	&.hide{
		animation: fadeOut 0.5s linear;
	}
}

.segura__telefones{
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md){
		flex-direction: column;

		margin: 12px 0px;
	}

	.icone__telefone{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 40px;
		height: 40px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 50%;

		color: #f74753;
	}

	.segura__links{
		display: flex;
		flex-direction: column;

		padding-left: 10px;

		@include media-breakpoint-down(md){
			padding-top: 8px;
			padding-left: unset;

			display: flex;
			align-items: center;
		}

		.link__tel{
			font-family: 'RobotoRegular';
			font-size: 14px;
			line-height: 1.2;
		}
	}
}

.segura__email{
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-breakpoint-down(md){
		flex-direction: column;

		margin: 12px 0px;
	}

	.icone__email{
		display: flex;
		align-items: center;
		justify-content: center;

		width: 40px;
		height: 40px;

		background-color: transparent;

		border: 2px solid #f74753;
		border-radius: 50%;

		color: #f74753;
	}

	.segura__links{
		display: flex;
		flex-direction: column;

		padding-left: 10px;

		@include media-breakpoint-down(md){
			padding-top: 8px;
			padding-left: unset;
		}

		.link__email{
			font-family: 'RobotoRegular';
			font-size: 14px;
			line-height: 1.2;
		}
	}
}

.btn__blog__link{
	display: flex;
	align-items: center;
	justify-content: center;

	width: 180px;
	height: 40px;

	background-color: transparent;

	border: 2px solid #f74753;
	border-radius: 30px;

	font-family: 'RobotoBold';
	font-size: 14px;
	line-height: 1.2;
	text-decoration: none!important;
	color: #f74753;

	&:hover{
		color: #f74753;
	}

	@include media-breakpoint-down(md){
		margin-left: auto!important;
		margin-right: auto!important;

		margin: 12px 0px;
	}
}

@include media-breakpoint-up(lg) {

	.main__cima{
		padding: 10px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.main__baixo{
		padding: 10px 0px;
		background-color: #f74753;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	.topo__mobile{
		display: none;
	}

	.segura__logo{
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0px 10px;
	}

	.main__menu{
		display: flex;
		text-align: center;
		justify-content: space-between;
		flex-grow: 1;
		line-height: 1.2;
		margin: 0;
		padding: 0;

		.menu__item{
			list-style-type: none;
			display: flex;

			a{
				display: flex;
				align-items: center;

				position: relative;

				font-family: 'RobotoMedium';
				font-size: 15px;
				text-decoration: none;
				color: #fff;

				&:before{
					width: 100%;
					height: 5px;

					content: '';
					position: absolute;

					background-color: #FFF;

					bottom: -10px;
					left: calc(50% - 50%);

					opacity: 0;
					transition: all 0.5s linear;
				}
			}

			&:hover{

				a {
					color: #fff;

					transition: all 0.5s linear;

					&:before{
						opacity: 1;
					}
				}

			}

			&.menu__item--active{

				a{
					color: #fff;

					&:before{
						opacity: 1;
					}
				}
			}
		}
	}

	.topo__main{
		position: relative;
		z-index: 1000;
		background-color: transparent;
	}

	@include media-breakpoint-only(lg) {
		.segura__logo{
			max-width: 285px;
		}
	}

}

@include media-breakpoint-down(md){

	.segura__logo{
		display: flex;
		align-items: center;
	}

	.main__menu{
		display: flex;
		text-align: center;
		justify-content: center;
		flex-direction: column;
		font-size: 20px;
		margin-top: 20px;
		padding: 0px;
		border-top: 2px solid #f74753;
		border-bottom: 2px solid #f74753;

		.menu__link{
			display: flex;
			align-items: center;

			width: 100%;

			font-family: 'RobotoMedium';
			font-size: 17px;
			text-decoration: none;
			color: #f74753;

			padding: 10px;
		}
		.menu__item{
			list-style-type: none;
			text-align: center;
			justify-content: center;
			&:hover{
				transition: all 0.5s linear;
				background-color: #f74753;

				.menu__link{
					color: #FFF;
				}
			}

			&.menu__item--active{
				background-color: #f74753;

				.menu__link{
					color: #FFF;
				}
			}
		}
	}

	.topo__main{
		width: 250px;
		height: 100%;
		overflow-y: auto;
		position: fixed;
		background-color: #FFF;
		left: -250px;
		z-index: 1080;
		transition: all 0.5s linear;
		display: flex;
		flex-direction: column;

		.container{
			padding: 0px;

			.segura__logo{
				display: flex;
				align-items: center;
				justify-content: center;

				padding: 20px 0px;
			}
		}

		&.shown{
			left: 0px;
		}
	}

	.topo__mobile{
		background-color: #fff;
		box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);
		-webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);
		-moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.43);

		.container{
			padding-bottom: 10px;
			padding-top: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.mbl__toggler{
			background-color: transparent;
			border: none;
			color: #f74753;
			font-size: 30px;
		}
	}
}
